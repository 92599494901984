import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Events from './components/Events';
import Events3 from './components/Events3';
import EventSeatPrintOut from './components/EventSeatPrintOut';
import EventDetail from './components/EventDetail';
import VideoPlayer from './components/VideoPlayer';
import Stripe1 from './components/Stripe1';
import Stripe2 from './components/Stripe2';
import ChargeCancel from './components/ChargeCancel';
import EventSeatChart2 from './components/EventSeatChart2';
import EventSeatInfo1 from './components/EventSeatInfo1';
import ChargeSuccess from './components/ChargeSuccess';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';

import '@aws-amplify/ui/dist/style.css';
Amplify.configure(awsconfig);


const MyTheme = {
  navButton: { 'backgroundColor': 'transparent', 'color': '#c6ebc6'},
  navBar: { 'backgroundColor': 'transparent', 'borderColor': 'transparent', 'float': 'right'},
  navItem: { 'backgroundColor': 'transparent', 'color': 'white', 'display': 'none',},
  nav: { 'backgroundColor': 'transparent', 'padding': 0, 'float': 'right'},
  navRight: { 'backgroundColor': 'transparent', 'marginTop': 0 }
}

const id = "testnav"

function App() {

  return (
    <div>
      <Header /> 
        <Router>
          <div className="link1">
            <Switch>
            <Route exact path="/" component={Home} />
              <Route exact path="/components/Home" component={Home} />
              <Route exact path="/components/EventSeatChart2" component={EventSeatChart2} />
              <Route exact path="/components/Events" component={Events} />
              <Route exact path="/components/Events3" component={Events3} />
              <Route exact path="/components/EventSeatPrintOut" component={EventSeatPrintOut} />
              <Route exact path="/components/EventDetail" component={EventDetail} />
              <Route exact path="/components/VideoPlayer" component={VideoPlayer} />
              <Route exact path="/components/Stripe1" component={Stripe1} />
              <Route exact path="/components/Stripe2" component={Stripe2} />
              <Route exact path="/components/ChargeSuccess" component={ChargeSuccess} />
              <Route exact path="/components/ChargeCancel" component={ChargeCancel} />
              <Route exact path="/components/EventSeatChart2" component={EventSeatChart2} />
              <Route exact path="/components/EventSeatInfo1" component={EventSeatInfo1} />
            </Switch>
          </div>
        </Router>
    </div>
  );
}

export default withAuthenticator(App, true, [], null, MyTheme);
