import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import EventDetail2 from './EventDetail2';
import { HashRouter as Router, Link,} from 'react-router-dom';



function Events() {

  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('123');
  const [search, setSearch] = useState('123');
  const [isLoading, setIsLoading] = useState(false);
  const [sk, setSk] = useState('event-2020-08-02-20-30'); 

  const [query2, setQuery2] = useState('Rom');
  const [search2, setSearch2] = useState('Rom');


    function getData(companyID) { 
      let apiName = 'ticket57api';
      let path = '/items/' + companyID;
      let myInit = { // OPTIONAL
          headers: {}, // OPTIONAL
          queryStringParameters:{
            "eventNameSearch": query2,
            "skPreFix": "event"
          }
      }
      return API.get(apiName, path, myInit);
    }
   
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
   
        const result = await getData(query);

        console.log('SPOT1')
        console.log("VVV",result)
   
        setData(result);
        setIsLoading(false);
      };
   
      fetchData();
    }, [search2]);
    console.log("555")
    console.log(data)
    console.log(sk)
   
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Show Name:</td>
              <td><input type="text" value={query2} onChange={event => setQuery2(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td></td>
              <td><button type="button" onClick={() => setSearch2({query2}) }> Search Show Name </button>
              </td>
            </tr>          
          </tbody>
        </table>
   
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div>
              {data.map( event => { 
                return <button className="show" onClick = {e => {setSk(event.SK)}}>{event.eventName} <br /> {event.dateTime} </button>
              })}   
          </div>
        )}
        <div className="clearall"></div>

        <EventDetail2 sk={sk} /> 
      </div>

    );




}


export default Events;
