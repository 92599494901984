
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import toccata from '../assets/toccata.png';

function VideoPlayer() {

    const [duration, setDuration] = useState({})
    const [watchComplete, setWatchComplete] = useState(false)

    function handleDuration(duration){
        console.log('onDuration', duration)
        setDuration({ duration })  
    }

    const handleWatchComplete = (state) => {
        console.log('onProgress', state.played)
    }
 
    return (
      <div>
        <ReactPlayer
          className='react-player'
          //url='https://vimeo.com/243556536'
          url='https://d209nu1ttbqeu8.cloudfront.net/75c0f980-1af3-45eb-9e81-cf3b96bbcd93/DASHISO1/toccata-hdtv.mpd'
          width='640px'
          height='360px'
          controls='true'
          //playIcon={<button className="playbutt">Play</button>}
          light={toccata}
          playing
          //onDuration={handleDuration}
          //onProgress={handleWatchComplete}
        />
      </div>
    )

}

<VideoPlayer />

export default VideoPlayer