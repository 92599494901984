import React from 'react';

function ChargeFailed() {

        return <div className="content">
        <div className="voice1">
        <h2>Your purchase was canceled:</h2>
        <p> Your purchase was canceled, and your credit card will not be charged. Any seats you reserved for this transaction will lose their reserved status.</p>
        <p>Thank you,</p> 
        <p>Ticket57</p> 

        </div>
        </div>
}

export default ChargeFailed
