import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import GetEventSeatsInfo from './GetEventSeatsInfo';

function PurchasePreview (props) {


    useEffect(() => {
        console.log("RESERVEDSEAT",props.reservedSeats)
    },[props.reservedSeats])

    return (
        <ol>
        {props.reservedSeats.map( seat => { 
          return <li>{seat}</li>
        })}
        </ol>              
    );
}

export default PurchasePreview