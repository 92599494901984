import React from 'react';

function Home() {

        return <div className="content">
        <div className="voice1">
        <h2>Welcome to the Ticket57 website:</h2>
        <p>Our goal is to provide a place to manage ticket reservations for events with assigned seating using a customizable, browser based,
          graphical interface, in order to faciltate the separation requirements mandated by the covid19 rules.
        </p>
        <p>This site is a work in progress, and while we greatly appreciate your comments, suggestions, and eagerness to help us in 
          providing the very best ticketing site available: we are not persisting data or accepting production level customers at 
          this time.</p>

        <p>Thank you,</p> 
        <p>Ticket57</p> 

        </div>
        </div>
}

export default Home
