import React from "react";
import MyNavBar from './MyNavBar';
import ticket57 from '../assets/ticket57-10.png';

function Header() {
    return (
        <div>
            <div className="divLeft">
                <img src={ticket57} alt="Logo" className="logo" />
            </div>
            <div className="divHeader1">
                <h1>Rochmanchester Community Theatre</h1>
            </div>
            <MyNavBar />
            <div className="divClear"></div>
            
        </div>
    )
}

export default Header