import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';

import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function StripeTest2() {
    const [eventName, setEventName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const stripePromise = loadStripe('pk_test_51I7lvpCh85wfodlb68REa63ukOFV0MiENdPMiJJXF34FxfyLyqhkTG8sNPdZVcGf6xU2t1nBv43FeLBugCeBu8g500yTkpfq4b');


    async function CheckOut() {
        const stripe = await stripePromise;
        let myInit = {
            body:{
                "Action": "create_checkout_session",
                "EmailAddress": emailAddress,
                "EventName": eventName,
                "Quantity": quantity
            }
        }
        const response = await API.post ('ticket57api', '/stripe', myInit)
        console.log("RESPONSE",response)
        //const session = await response.json();
        //console.log("SESSION",response)
        const result = await stripe.redirectToCheckout({
            sessionId: response,
          });


    }

    return (
        <div>
          <table>
            <tbody>
            <tr>
              <td>Email Address:</td>
              <td><input type="text" value={emailAddress} onChange={event => setEmailAddress(event.target.value)}/>
              </td>    
            </tr>  
            <tr>
              <td>EventName:</td>
              <td><input type="text" value={eventName} onChange={event => setEventName(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td>Quantity:</td>
              <td><input type="text" value={quantity} onChange={event => setQuantity(event.target.value)}/>
              </td>    
            </tr> 
              <tr>
                <td></td>
                <td><button type="button" onClick={() => CheckOut()}> Post Customer </button>
                </td>
              </tr>          
            </tbody>
          </table>
  
  
          <div className="clearall"></div>
  
        </div>
  
      );

}
export default StripeTest2