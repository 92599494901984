import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import Stripe4 from './Stripe4';
import GetEventSeatsInfo from './GetEventSeatsInfo';
import ChangeSeatStatus from './ChangeSeatStatus'
import PurchasePreview from './PurchasePreview'


function EventSeatChart2 () {

    const [event, setEvent] = useState({eventName: '',dateTime: ''});
    const [rows, setRows] = useState([]);
    const [reserved, setReserved] = useState([]);
    const [reserved2, setReserved2] = useState([]);
    const [seats, setSeats] = useState({});
    const [seat, setSeat] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [seatID, setSeatID] = useState('');
    const [seatStatus, setSeatStatus] = useState('');
    const [reload, setReload] = useState(0);
    const [SKRoot, setSKRoot] = useState('event-2021-01-01-20-30');
    const [loadEvent, setLoadEvent] = useState(SKRoot + '-details');


    function chgClassName(seat, status, reserved){
        if(reserved.indexOf(seat) > -1){
            return 'reserved';
        }else if (seat == 'ns') {
            return 'ns';
        }else if( seat in seats){
            return seats[seat];
        } else {
            return status;
        }
    }

    function onClickData(seat, reserved, setReserved) {
        if((seats[seat] !== 'sold') && (seats[seat] !== 'ns' )&& (seats[seat] !== 'reserved2')){
            if(reserved.indexOf(seat) > -1 ) {
                setReserved([])
                console.log("SPOT5:", reserved)
            }else{
                setReserved([...reserved, seat]);
                console.log("SPOT6:", reserved)
            }
        }
        console.log(seat)
        console.log("RESERVED", reserved)
    }

    function Refresh(){
        if(reload == 0){
            setReload(1);
        }else{
            setReload(0)
        }
    }

    async function ReserveSeatsInDB(seatStatus, firstName, lastName){
        reserved.map( rseat => {
            console.log("RSEAT", rseat)
            console.log("FIRSTNAME", firstName)
            let SKey = SKRoot + '-seat-' + rseat
            ChangeSeatStatus(rseat, seatStatus, "123", SKey, firstName, lastName);
        })
        setReserved2(reserved)
        setReserved([])
    }

    async function EventSeatChart() {
        console.log("SKRoot",SKRoot)
        console.log("loadEventt",loadEvent)
        let myInit = {
            body:{
                "Action": "getEventSeatChart",
                "SK": loadEvent,
                "PK": "123",
                "EventName": "Cats"
            }
        }
        const apiData = await API.post ('ticket57api', '/events', myInit)
        let response = await GetEventSeatsInfo("123", SKRoot + '-seat')
        let seatInfo = {};
        response.map( seat => {
            console.log("SEATS11",seat)
            seatInfo[seat.seatID] = seat.seatStatus
            console.log("SEATINFO",seatInfo)
        })
        setSeats(seatInfo)
        console.log("APIDATA",apiData)
        console.log("SEATS", seats)
        setEvent(apiData[0]);
        setRows(apiData[0].rows);
    }

    useEffect(() => {
        EventSeatChart()
    },[reload, loadEvent])

    return (
    <div className="App">
        <header className="App-header">
        <div className="seatTable">   
        <p><button onClick={e => setLoadEvent(SKRoot + '-details')}>Get Event:</button><input type="text" value={SKRoot} onChange={event => setSKRoot(event.target.value)}/></p>
        <table className="grid">
        <h1>{event.eventName}</h1>
        <h1>{event.dateTime}</h1>    
        <div className="seatTable">
        <tbody>             
              {rows.map( row => { 
                  return <tr id={row.rowId} key={row.rowId}>
                  {row.rowMap.map( seat => { 
                  //return <td id={seat}className="available"> {seat}
                  return <td id={seat} className={chgClassName(seat, "available", reserved)} key={seat} onClick = {e => {onClickData(seat, reserved, setReserved)}}>
                      {seat}
                  </td>
                  })}              
                  </tr>
                })}              
          </tbody>
        <div class="stage">STAGE</div>       
        </div>
        </table>
        <div class="seatinfo">
        <p>Customer First Name: <input type="text" value={firstName} onChange={event => setFirstName(event.target.value)}/></p>
        <p>Customer Last Name: <input type="text" value={lastName} onChange={event => setLastName(event.target.value)}/></p>
        <p>Customer Email: <input type="text" value={emailAddress} onChange={event => setEmailAddress(event.target.value)}/></p>
        <p>Seats:</p><PurchasePreview reservedSeats={reserved} />
        <p><button onClick={e => {ReserveSeatsInDB("reserved2", firstName, lastName ); Refresh()}}>Reserve Seats</button></p>
        <p><button onClick={e => {ReserveSeatsInDB("sold", firstName, lastName); Stripe4(event.eventName + "-" + event.dateTime, emailAddress, reserved)}}>Purchase Seats</button></p>
        <p><button onClick={e => Refresh()}>Refresh</button></p>
        </div>
        </div>
        </header>
    </div>
    );
}

export default EventSeatChart2