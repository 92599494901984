/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ticket57dynamodb-master",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:75512067-a53b-4e47-8059-13fb18d872ee",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RCLu1oWyp",
    "aws_user_pools_web_client_id": "23gr06d9sbqhb0a4c5rj3akqms",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "ticket57api",
            "endpoint": "https://qstknyzs6b.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
