import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { HashRouter as Router, Link,} from 'react-router-dom';


function StripeTest() {

  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

    async function postCustomer() {
      let myInit = {
          body:{
              "Action": "customer_create",
              "Name": customerName,
              "Email": customerEmail,
              "Phone": "555-555-5555",
              "Address": {"city": "Tallahssee", "state": "Florida"},
              "Description": "My Second Customer"
          }
      }
      const apiData = await API.post ('ticket57api', '/stripe', myInit)
    }
  
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td><input type="text" value={customerName} onChange={event => setCustomerName(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td>Email:</td>
              <td><input type="text" value={customerEmail} onChange={event => setCustomerEmail(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td></td>
              <td><button type="button" onClick={() => postCustomer()}> Post Customer </button>
              </td>
            </tr>          
          </tbody>
        </table>


        <div className="clearall"></div>

      </div>

    );

}
export default StripeTest
