import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';

import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

function Stripe4(eventName, email, reserved, seatID, transactionID) {

    const stripePromise = loadStripe('pk_test_51I7lvpCh85wfodlb68REa63ukOFV0MiENdPMiJJXF34FxfyLyqhkTG8sNPdZVcGf6xU2t1nBv43FeLBugCeBu8g500yTkpfq4b');

    async function CheckOut() {
        const stripe = await stripePromise;
        let lineItems = [];
        
        reserved.map( rseat => {
            let fullSeatID = eventName + '-seat-' + rseat
            lineItems.push({'price_data': {'currency':'usd', 'product_data': {'name': fullSeatID,},'unit_amount': 2000,},'quantity': 1,})
        })
        let myInit = {
            body:{
                "Action": "create_checkout_session",
                "EventName": eventName,
                "EmailAddress": email,
                "SeatID": seatID,
                "TransactionID": transactionID,
                "Quantity": 1,
                "LineItems": lineItems,
            }
        }
        const response = await API.post ('ticket57api', '/stripe', myInit)
        console.log("RESPONSE",response)
        //const session = await response.json();
        //console.log("SESSION",response)
        const result = await stripe.redirectToCheckout({
            sessionId: response,
          });
    }

    CheckOut();

}
export default Stripe4