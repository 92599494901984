import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';


function GetEventSeatsInfo(eventPK, eventSK) {
    async function GetSeatsInfo() {
        let myInit = {
            body:{
                "Action": "getSeatInfo",
                "SK": eventSK,
                "PK": eventPK
            }
        }
        const apiData = await API.post ('ticket57api', '/events', myInit)
        //console.log("APIDATA",apiData)
        return apiData
    }
    let response = GetSeatsInfo()
    return response
}


export default GetEventSeatsInfo