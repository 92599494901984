import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import EventDetail3 from './EventDetail3';
import EventSeatChart from './EventSeatChart';

function Events3() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sk, setSk] = useState('event');
  const [skDetail,setSkDetail] = useState('event-2020-08-02-20-30'); 
  const [query2, setQuery2] = useState('Rom');
  const [search2, setSearch2] = useState('Rom');

    useEffect(() => {
        async function getEvents() {
          setIsLoading(true);
          let myInit = {
              body:{
                  "Action": "getEvents",
                  "SK": sk,
                  "PK": "123",
                  "EventName": search2
              }
          }
          const apiData = await API.post ('ticket57api', '/events', myInit)
          setEvents(apiData)
          setIsLoading(false);
          setSkDetail(apiData[0].SK)
          console.log("EVENTS",apiData[0].SK)
        }
        getEvents()
    },[search2])
  
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Event Name:</td>
              <td><input type="text" value={query2} onChange={event => setQuery2(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td></td>
              <td><button type="button" onClick={() => setSearch2(query2)}> Search Event Name </button>
              </td>
            </tr>          
          </tbody>
        </table>
   
        {isLoading ? (
          <div>Loading ...</div>
        ) : 
        (
          <div>
              {events.map( event => { 
                return <button className="show" onClick = {e => {setSkDetail(event.SK)}}>{event.eventName} <br /> {event.dateTime} </button>
              })}   
          </div>
        )}

        <div className="clearall"></div>
        <EventSeatChart sk={skDetail} />
      </div>

    );




}


export default Events3;
