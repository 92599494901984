import React from 'react';

function Test3(props){
 return (
        <div class="seatinfo">
         <p>Customer: {props.arr.guest}<br />
         Seat: {props.arr.seatId}<br />
         Seat Status: {props.arr.seatStatus}<br />
         Purchase Date: {props.arr.purchaseDate}<br />
         Purchase Price:  ${props.arr.purchasePrice}
         </p>

        </div>
        ) 
}

export default Test3;