import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import EventSeatListPrintOut from './EventSeatListPrintOut';
import { HashRouter as Router, Link,} from 'react-router-dom';



function EventSeatPrintOut() {

  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('123');
  const [search, setSearch] = useState('123');
  const [isLoading, setIsLoading] = useState(false);
  const [sk, setSk] = useState('event'); 
  const [skDetail, setSkDetail] = useState('event-2020-08-02-20-30');

  const [query2, setQuery2] = useState('');
  const [search2, setSearch2] = useState('');


    function getData(eventName) { 
      let myInit = {
        body:{
            "Action": "getEvents",
            "SK": sk,
            "PK": query,
            "EventName": eventName
        }
      }
      return API.post ('ticket57api', '/events', myInit)
    }

   
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
   
        const result = await getData(query2);

        console.log('SPOT1')
        console.log("VVV",result)
   
        setData(result);
        setIsLoading(false);
      };
   
      fetchData();
    }, [search2]);
    console.log("555")
    console.log(data)
    console.log(sk)
   
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Show Name:</td>
              <td><input type="text" value={query2} onChange={event => setQuery2(event.target.value)}/>
              </td>    
            </tr>
            <tr>
              <td></td>
              <td><button type="button" onClick={() => setSearch2({query2}) }> Search Show Name </button>
              </td>
            </tr>          
          </tbody>
        </table>
   
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div>
              {data.map( event => { 
                return <button className="show" onClick = {e => {setSkDetail(event.SK)}}>{event.eventName} <br /> {event.dateTime} </button>
              })}   
          </div>
        )}
        <div className="clearall"></div>
        <div className="content">
        <EventSeatListPrintOut sk={skDetail} /> 
        </div>
      </div>

    );




}


export default EventSeatPrintOut;
