import React from 'react';

function ChargeSuccessful() {

        return <div className="content">
        <div className="voice1">
        <h2>Your purchase was successful:</h2>
        <p>Thank you,</p> 
        <p>Ticket57</p> 

        </div>
        </div>
}

export default ChargeSuccessful
